// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import "stylesheets/application.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import "@hotwired/turbo-rails";
import "channels";
import "controllers";
import "trix/dist/trix";

// Rich text support
require("trix")
require("@rails/actiontext")

require("jquery")

// Activate Active Storage
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// Turbo-iOS & Turbo-Android setup
import { Turbo } from "@hotwired/turbo-rails"
import Bridge from "turbo/bridge.js"
window.Turbo = Turbo
window.bridge = Bridge
import "@hotwired/turbo-rails"
